import { FC } from 'react'
import { Button } from '@components/ui'
import Link from 'next/link'
import cn from 'classnames'

interface props {
  primary: {
    block_title?: string
    button_texts?: string
    button_link?: string
    background_colour?: string
    padding_top?: boolean
  }
  applyWrapper?: boolean
}

const CTAButton: FC<props> = ({ primary, applyWrapper = true }) => {
  const { block_title, button_texts, button_link, background_colour, padding_top } = primary || {}
  return (
    <div style={{ backgroundColor: background_colour }}>
      <div
        className={cn('pb-40 flex justify-center laptop:pb-80', {
          wrapper: applyWrapper,
          'pt-40 tablet:pt-80': padding_top,
        })}
      >
        <div>
          {block_title && <h2 className="heading-2 pb-32 text-center laptop:heading-4 laptop:pb-40">{block_title}</h2>}

          <div className="flex justify-center">
            {button_texts && (
              <Link href={button_link || '/'} passHref legacyBehavior>
                <Button variant="primary">{button_texts}</Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CTAButton
